<template>
  <div class="col-12 col-lg-8 c g">
    <div class="card">
      <div class="card-header">
        <h4>
          <i class="fa fa-message"></i>
          اعدادات الرسائل والاشعارات
        </h4>
      </div>
      <div class="card-body">
        <b-form-checkbox
          v-model="nonotis"
          name="check-button"
          class="custom-control-danger"
          switch
          inline
        >
          <h5>
            <i class="fa fa-ban"></i>
            ايقاف ارسال جميع اشعارات الرحلات
          </h5>
        </b-form-checkbox>
        <span class="text-danger" v-if="nonotis == true || nonotis == 'true'">
          <br />
          لن يتم ارسال لاولياء الامور اشعارات ببدء او انتهاء الرحلات او ركوب او
          نزول الابناء</span
        >
        <hr />
        <b-form-checkbox
          v-model="send_up_down"
          name="check-button"
          class="custom-control-success"
          switch
          inline
        >
          <h5>
            <i class="fa fa-sign-in"></i>
            ارسال اشعارات لاولياء الامور بركوب ونزول ابنهم من الباص
          </h5>
        </b-form-checkbox>
        <hr />
        <b-form-checkbox
          v-model="send_neaby"
          name="check-button"
          class="custom-control-success"
          switch
          inline
        >
          <h5>
            <i class="fa fa-sign-in"></i>
            ارسال اشعار لاولياء الامور باقتراب الباص
          </h5>
        </b-form-checkbox>
        <hr />
        <b-form-checkbox
          v-model="send_fcm"
          name="check-button"
          class="custom-control-success"
          switch
          inline
        >
          <h5>
            <i class="fa fa-bell"></i>
            ارسال الاشعارات عن طريق "الإشعارات العادية"
          </h5>
        </b-form-checkbox>
        <hr />
        <b-form-checkbox
          v-model="send_whatsapp"
          name="check-button"
          switch
          class="custom-control-success"
          inline
        >
          <h5>
            <img :src="require('@/assets/images/whatsapp.png')" alt="" />
            ارسال الاشعارات عن طريق "الواتساب"
          </h5>
        </b-form-checkbox>
        <div
          class="col-12 g"
          v-if="send_whatsapp == true || send_whatsapp == 'true'"
        >
          <div
            class="card card-body border"
            style="background: #eee; border: 2px solid #ddd !important"
          >
            <span>
              يمكنك الحصول على بيانات الربط من خلال الموقع الرسمي
              <a
                href="https://app.smart-whats.com/api"
                target="_blank"
                class="text-success"
                >https://smart-whats.com</a
              ></span
            >
            <br />
            <div class="form-group">
              <label for="">الـ JWT الخاص بك </label>
              <input
                type="text"
                class="form-control"
                placeholder="الصق هنا..."
                dir="ltr"
                v-model="jwt"
              />
            </div>
            <div class="form-group">
              <label for=""
                >ارقام الاجهزة المراد الارسال منها (يمكنك الارسال من اكثر من
                رقم)</label
              >
              <input
                type="text"
                class="form-control"
                placeholder="الصق هنا..."
                dir="ltr"
                v-model="devices"
              />
            </div>
          </div>
        </div>
        <div class="col-12 g text-center">
          <button class="btn btn-success" @click="save()">
            <i class="fa fa-save"></i>
            حفظ الاعدادات
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
export default {
  components: {
    BFormCheckbox,
  },
  data() {
    return {
      nonotis: false,
      send_up_down: false,
      accept_all_devices: false,
      send_fcm: false,
      send_neaby: false,
      send_whatsapp: false,
      jwt: "",
      devices: "",
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    if (!checkPer("settings")) {
      this.$router.push("/per");
      return false;
    }
    var g = this;
    $.post(api + "/user/general/messages-config", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.nonotis = r.response.nonotis;
        g.send_up_down = r.response.send_up_down;
        g.accept_all_devices = r.response.accept_all_devices;
        g.send_fcm = r.response.send_fcm;
        g.send_whatsapp = r.response.send_whatsapp;
        g.send_neaby = r.response.send_neaby;
        g.jwt = r.response.jwt;
        g.devices = r.response.devices;
      })
      .catch(function () {
        alert("حدث خطا في الاتصال");
      });
  },
  methods: {
    save() {
      var g = this;
      $.post(api + "/user/general/messages-config-save", {
        jwt: g.user.jwt,
        nonotis: g.nonotis,
        send_up_down: g.send_up_down,
        accept_all_devices: g.accept_all_devices,
        send_fcm: g.send_fcm,
        send_whatsapp: g.send_whatsapp,
        send_neaby: g.send_neaby,
        _jwt: g.jwt,
        devices: g.devices,
      })
        .then(function (r) {
          if (r.status == 100) {
            alert("تم الحفظ");
          } else {
            alert("حدث خطأ");
          }
        })
        .catch(function () {
          alert("حدث خطا في الاتصال");
        });
    },
  },
};
</script>

<style>
</style>